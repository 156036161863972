import { ActionButton, IconButton, Surface } from "@able/react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { fetchInventoryCount } from "services/api/inventory-service";
import useTenancy from "services/hooks/useTenancy";
import { ableUrl } from "utils/constants";
import "./ServiceTierCounts.scss";

export const ServiceTierCounts = () => {
  const navigate = useNavigate();
  const { data: tenant } = useTenancy();
  const { data, status } = useQuery({
    queryKey: ["inventory-count"],
    queryFn: () => tenant?.tenancyId && fetchInventoryCount(tenant.tenancyId),
    enabled: !!tenant?.tenancyId,
  });

  function getProcessingCount() {
    return data.numSubsTotal - (data.numSubsHigh + data.numSubsMedium + data.numSubsLow + data.numSubsDefault);
  }

  return (
    <Surface variant="SurfaceSlight" className="surface-count">
      <>
        <div className="surface-count__header">
          <h2>Service Tier Count</h2>
          <IconButton
            aria-label="Services"
            //@ts-ignore
            icon="ArrowRight"
            developmentUrl={ableUrl}
            events={{ onClick: () => navigate("/services") }}
          />
        </div>

        <dl>
          {/**
           * WHATWG HTML allows wrapping each name-value group in a dl emement in a div
           * Ref: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/dl#wrapping_name-value_groups_in_div_elements
           */}
          <div className="description-group">
            <dt>High</dt>
            {status !== "success" ? <dd className="loading">loading</dd> : <dd>{data.numSubsHigh}</dd>}
          </div>
          <div className="description-group">
            <dt>Medium</dt>
            {status !== "success" ? <dd className="loading">loading</dd> : <dd>{data.numSubsMedium}</dd>}
          </div>
          <div className="description-group">
            <dt>Low</dt>
            {status !== "success" ? <dd className="loading">loading</dd> : <dd>{data.numSubsLow}</dd>}
          </div>
          <div className="description-group">
            <dt>Default</dt>
            {status !== "success" ? <dd className="loading">loading</dd> : <dd>{data.numSubsDefault}</dd>}
          </div>
          <div className="description-group">
            <dt>Processing</dt>
            {status !== "success" ? <dd className="loading">loading</dd> : <dd>{getProcessingCount()}</dd>}
          </div>
          <div className="description-group">
            <dt>Total</dt>
            {status !== "success" ? <dd className="loading">loading</dd> : <dd>{data.numSubsTotal}</dd>}
          </div>
        </dl>

        <ActionButton
          label="View all services"
          variant="LowEmphasis"
          element="button"
          developmentUrl={ableUrl}
          events={{ onClick: () => navigate("/services") }}
        />
      </>
    </Surface>
  );
};

import { MessageSection, SectionHeader, Spacing, TextStyle } from "@able/react";
import { useRef } from "react";
import { Helmet } from "react-helmet-async";
import { useBreadCrumbs } from "services/hooks/useBreadcrumbs";
import useProvisioningConfig from "services/hooks/useProvisioningConfig";
import { ableUrl } from "utils/constants";

export default function ApnPage() {
  const headingRef = useRef(null);
  useBreadCrumbs([{ label: "Dashboard", to: "/" }]);
  const { data, isLoading, isError, error } = useProvisioningConfig();

  if (isError) {
    console.error(error);
    return <p>An Error occurred fetching the provisioning config</p>;
  }

  return (
    <main>
      <Helmet>
        <title>Access Point Names - Telstra Dedicated Networks</title>
      </Helmet>
      <Spacing top="spacing4x" bottom="spacing4x">
        <TextStyle alias="HeadingA" element="h1" elementRef={headingRef}>
          Access point names
        </TextStyle>
      </Spacing>
      <Spacing top="spacing4x" bottom="spacing4x">
        <SectionHeader title="APN list" variantLevel="Level1" headingElement="h2" />
        {isLoading ? <p>Loading APNs</p> : null}
        {data ? (
          <div style={{ display: "flex", gap: "40px" }}>
            <table style={{ flex: "1 1 0" }}>
              <thead>
                <tr>
                  <th>Name</th>
                </tr>
              </thead>
              <tbody>
                {data.apn.map(({ value }) => (
                  <tr>
                    <td>{value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div style={{ flex: "1 1 0" }}>
              <MessageSection
                variant="Information"
                developmentUrl={ableUrl}
                description="I have information in me that is useful"
              />
            </div>
          </div>
        ) : null}
      </Spacing>
    </main>
  );
}

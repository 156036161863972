import { useQuery } from "@tanstack/react-query";
import { fetchNotificationRecipient } from "services/api/account-service";
import useTenancy from "./useTenancy";

type Props = {
  recipientId: string | undefined;
};

/**
 * Returns a useQuery hook for returning the notification recipients, with initial data is an empty array.
 */
export default function useNotificationRecipient({ recipientId }: Props) {
  // There is only ever a single tenant related to the CIDN in the token.
  const { data: tenantData, isLoading, isError } = useTenancy();

  // !! is to transform the object or value into a boolean, not a truthy/falsy
  // When we have tenant data AND recipientID AND NOT loading AND NOT error.
  const hookEnabled = !!tenantData && !!recipientId && !isLoading && !isError;

  const recipientQuery = useQuery({
    queryKey: ["notification-recipients", recipientId],
    queryFn: () => tenantData && fetchNotificationRecipient(tenantData.tenancyId, recipientId as string),
    enabled: hookEnabled,
    refetchOnWindowFocus: false,
  });

  const isLoadingEitherQuery = isLoading || recipientQuery.isLoading;

  return { tenantId: tenantData?.tenancyId, ...recipientQuery, isLoading: isLoadingEitherQuery };
}

import { useEffect, useRef } from "react";

type Props = {
  callback: () => void;
  listenClicks?: boolean;
  listenTabs?: boolean;
};

export function useNavigatedOutside<Element extends HTMLElement>({
  callback,
  listenClicks = false,
  listenTabs = false,
}: Props) {
  const ref = useRef<Element>(null);

  useEffect(() => {
    const callbackHandler = ({ current, target }: { current: Element | null; target: EventTarget | null }) => {
      if (current && target && current.contains(target as Node)) callback();
    };

    const eventHandler = (event: MouseEvent | KeyboardEvent) => {
      if (event instanceof MouseEvent && event.type === "click") {
        callbackHandler({ current: ref.current, target: event.target });
      }

      if (event instanceof KeyboardEvent && event.type === "keydown" && event.key === "Tab") {
        callbackHandler({ current: ref.current, target: event.target });
      }
    };
    if (listenClicks) document.addEventListener("click", eventHandler, true);
    if (listenTabs) document.addEventListener("keydown", eventHandler, true);

    return () => {
      if (listenClicks) document.removeEventListener("click", eventHandler, true);
      if (listenTabs) document.removeEventListener("keydown", eventHandler, true);
    };
  }, [callback, listenClicks, listenTabs]);

  return ref;
}

import { ActionButton, Lozenge, MessageInline, Spacing } from "@able/react";
import { ServiceTierRadioGroup } from "components/ServiceTierRadioGroup/ServiceTierRadioGroup";
import { SheetWithButton } from "components/ui/SheetWithButton";
import { queryClient } from "index";
import { useState } from "react";
import { Navigate } from "react-router-dom";
import { activateSubscription, updateSubscriptionStateById } from "services/api/inventory-service";
import { deactivateHybridService } from "services/api/mica-service";
import { Subscription } from "types";
import { formatters } from "utils";
import { ableUrl } from "utils/constants";
import { BlockModalWithButton, DeactivateModalWithButton, UnblockModalWithButton } from "./StatusModals";
import "./styles.scss";

type Props = {
  subscription: Subscription;
};

export function ServiceStatus({ subscription }: Props) {
  const { state, targetState, subscriptionType } = subscription;
  const formattedState = formatters.state(state, targetState);
  const labelVariant = state === "active" && !targetState ? "PositiveEmphasis" : "NeutralEmphasis";
  const serviceType = subscriptionType === "TDN_STANDARD" ? "TDN Standard" : "TDN Hybrid";
  const [targetServiceTier, setTargetServiceTier] = useState(subscription.serviceTier.value);

  // Things to be aware of:
  // The callback will be different depening on the type of service (Hybrid or Standard)
  // If the service is transitioning, we should not show any buttons.
  // If the service is in another state we can show buttons.

  function renderModalButtons() {
    if (targetState) return null;

    if (state === "pendingActivation") {
      // TDN_HYBRID ACTIVATE
      // TODO - consolidate the hybrid activation and the standard activation.
      if (subscription.subscriptionType === "TDN_HYBRID") {
        return (
          <ActionButton
            element="Link"
            label="Activate service"
            variant="HighEmphasisCompact"
            to={`/services/create?subId=${subscription.id}`}
          />
        );
      }

      return (
        <SheetWithButton
          buttonLabel="Active service"
          buttonVariant="HighEmphasisCompact"
          sheetHeading="Activate service"
        >
          <div style={{ display: "flex", flexDirection: "column", gap: "1rem", paddingTop: "1rem" }}>
            <MessageInline
              variant="Attention"
              description={
                <>
                  The service number needs to be <a href="/apns">added to the RADIUS server</a> to ensure it is
                  authenticated and operates correctly.
                </>
              }
              developmentUrl={ableUrl}
            />
            <ServiceTierRadioGroup
              state={targetServiceTier}
              onChange={(e) => setTargetServiceTier(e.currentTarget.value)}
            />
            <ActionButton
              element="button"
              label="Activate"
              variant="HighEmphasisCompact"
              events={{
                onClick: () => {
                  if (subscription.subscriptionType === "TDN_STANDARD") {
                    activateSubscription({
                      subscriptionId: subscription.id,
                      body: { isServiceAreaRestricted: false, serviceTier: targetServiceTier },
                    })
                      .then(() => {
                        alert("Service updated!");
                        queryClient.invalidateQueries({ queryKey: ["subscriptions", subscription.id] });
                      })
                      .catch(console.error);
                  }
                  // else {
                  //   activateHybridService()
                  // }
                },
              }}
            />
          </div>
        </SheetWithButton>
      );
    }

    if (state === "active") {
      return (
        <>
          <DeactivateModalWithButton
            callback={() => {
              if (subscription.subscriptionType === "TDN_STANDARD") {
                updateSubscriptionStateById(subscription, "TERMINATE")
                  .then(() => Navigate({ to: "/services" }))
                  .catch(console.error);
              } else {
                deactivateHybridService(subscription)
                  .then(() => Navigate({ to: "/services" }))
                  .catch(console.error);
              }
            }}
          />
          <BlockModalWithButton
            callback={() => {
              updateSubscriptionStateById(subscription, "BAR")
                .then(() => {
                  alert("Service updated!");
                  queryClient.invalidateQueries({ queryKey: ["subscriptions", subscription.id] });
                })
                .catch(console.error);
            }}
          />
        </>
      );
    }

    if (state === "blocked") {
      return (
        <>
          <DeactivateModalWithButton
            callback={() => {
              if (subscription.subscriptionType === "TDN_STANDARD") {
                updateSubscriptionStateById(subscription, "TERMINATE")
                  .then(() => Navigate({ to: "/services" }))
                  .catch(console.error);
              } else {
                deactivateHybridService(subscription)
                  .then(() => Navigate({ to: "/services" }))
                  .catch(console.error);
              }
            }}
          />
          <UnblockModalWithButton
            callback={() => {
              updateSubscriptionStateById(subscription, "UNBAR")
                .then(() => {
                  alert("Service updated!");
                  queryClient.invalidateQueries({ queryKey: ["subscriptions", subscription.id] });
                })
                .catch(console.error);
            }}
          />
        </>
      );
    }
  }

  return (
    <div className="service-status-container">
      <p className="service-status-label">Service Status</p>
      <p className="service-status-fineprint">
        Any changes may take up to 24 hours. The service number needs to be added to the RADIUS server to ensure it is
        authenticated and operates correctly.
      </p>
      <Spacing top="spacing1nHalf" bottom="spacing2x" className={["lozenge-group"]}>
        <Lozenge label={serviceType} variant="Neutral" />
        <Lozenge label={formattedState} variant={labelVariant} />
      </Spacing>
      <div className="service-status-buttons">{renderModalButtons()}</div>
    </div>
  );
}

import { ActionButton, ModalDialogConfirmation } from "@able/react";
import { useRef } from "react";
import { ableUrl } from "utils/constants";

type Props = {
  active: boolean;
  onConfirmation: () => void;
};

export default function UpdateStatusModal({ active, onConfirmation }: Props) {
  const statusModalRef = useRef<HTMLDialogElement>(null);
  const buttonRef = useRef(null);

  const targetStatus = active ? "block" : "unblock";

  return (
    <>
      <ModalDialogConfirmation
        developmentUrl={ableUrl}
        title={`Confirm user ${targetStatus}`}
        cancelButtonLabel="Cancel"
        confirmButtonLabel="Confirm"
        stackButtonOnVXS
        focusOnCloseRef={buttonRef}
        description={`You are about to ${targetStatus} this user. Are you sure you wish to continue?`}
        confirmButtonEvents={{ onClick: onConfirmation }}
        cancelButtonEvents={{ onClick: () => statusModalRef.current?.close() }}
      >
        <ActionButton
          elementRef={buttonRef}
          element="button"
          label={active ? "Block" : "Unblock"}
          variant="MediumEmphasis"
          developmentUrl={ableUrl}
        />
      </ModalDialogConfirmation>
    </>
  );
}

type Props = {
  title: string;
  description?: string;
};

export function LabelledSection({ title, description }: Props) {
  return (
    <div>
      <p className="alias-LabelA1">{title}</p>
      <p className="alias-Base">{description}</p>
    </div>
  );
}

import { useQuery } from "@tanstack/react-query";
import { fetchTenant } from "services/api/account-service";

export default function useTenancy() {
  return useQuery({
    queryKey: ["tenant"],
    queryFn: () => fetchTenant(),
    select: (data) => data[0],
    staleTime: 5 * 60 * 1000,
  });
}

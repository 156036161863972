import useTenancy from "services/hooks/useTenancy";
import { createNotificationRecipient, RecipientForm } from "../components/RecipientForm";
import { TRecipientFormValues } from "../components/RecipientForm/RecipientFormSchema";

export function CreateRecipientForm() {
  const { data, isLoading } = useTenancy();

  if (isLoading) return <p>Loading tenancy data</p>;

  if (!data) return <p>No tenancy data!</p>;

  return (
    <RecipientForm
      template="Create"
      formAction={(formData: TRecipientFormValues) => createNotificationRecipient(formData, data.tenancyId)}
    />
  );
}

import { useQuery } from "@tanstack/react-query";
import { fetchMicaData } from "services/api/account-service";
import useTenancy from "./useTenancy";

export default function useProvisioningConfig() {
  const tenant = useTenancy();
  const provisioningConfig = useQuery({
    queryKey: ["provisioning-config"],
    queryFn: () => tenant.data && fetchMicaData(tenant.data.tenancyId),
    enabled: !!tenant.data?.tenancyId,
    staleTime: 3 * 60 * 1000,
  });

  const isLoadingAny = tenant.isLoading || provisioningConfig.isLoading;

  return { ...provisioningConfig, isLoading: isLoadingAny, tenant: tenant.data };
}

import { AxiosRequestConfig } from "axios";
import { Subscription, SubscriptionResponse } from "types";
import axiosInstance from "utils/axiosInstance";

interface IValidateHybridServiceNumber {
  serviceNumber: string;
  body: { cidn: string };
  options?: AxiosRequestConfig;
}

interface IActivateHybridService {
  body: {
    tenancyId: string;
    subscriptionRef: string;
    serviceTierTo: string;
    serviceNumber: string;
  };
  options?: AxiosRequestConfig;
}

export async function validateHybridServiceNumber({
  serviceNumber,
  body,
  options,
}: IValidateHybridServiceNumber): Promise<SubscriptionResponse> {
  // We need to strip the whitespaces from the service number
  const spacelessServiceNumber = serviceNumber.replace(/\s/g, "");
  const response = await axiosInstance.post(
    `/api/tdn/mica-adapter/v1/hybrid/subscriptions/validate/${spacelessServiceNumber}`,
    body,
    options
  );

  const data = response.data;
  return data;
}

export async function activateHybridService({ body, options }: IActivateHybridService): Promise<SubscriptionResponse> {
  const response = await axiosInstance.post(`/api/tdn/mica-adapter/v1/hybrid/subscriptions/activate`, body, options);

  const data = response.data;
  return data;
}

export async function deactivateHybridService(
  subscription: Subscription,
  options?: AxiosRequestConfig
): Promise<SubscriptionResponse> {
  const body = {
    tenancyId: subscription.tenancyId,
    serviceNumber: subscription.serviceNumber,
    subscriptionRef: subscription.id,
    serviceTier: subscription.serviceTier.value,
  };

  const response = await axiosInstance.post(`/api/tdn/mica-adapter/v1/hybrid/subscriptions/deactivate`, body, options);

  const data = response.data;
  return data;
}

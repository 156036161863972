import { AxiosRequestConfig } from "axios";
import { NotificationRecipient, ProvisioningConfig, TdnResponse, TenantData } from "types";
import { decodeToken } from "utils";
import axiosInstance from "utils/axiosInstance";

export async function fetchNotificationRecipient(tenantId: string, recipientId: string) {
  const response = await axiosInstance.get(
    `/api/tdn/account/v1/tenants/${tenantId}/notification-recipients/${recipientId}`
  );

  const data = response.data?.data;
  return data;
}

export async function fetchNotificationRecipients(tenantId: string) {
  const response = await axiosInstance.get<TdnResponse<NotificationRecipient[]>>(
    `/api/tdn/account/v1/tenants/${tenantId}/notification-recipients`
  );

  const data = response.data?.data;
  return data;
}

export async function fetchTenancyData() {
  const decodedToken = decodeToken();

  try {
    // Fix the types here
    const response = await axiosInstance.get("/api/tdn/account/v1/tenants", { params: { cidn: decodedToken?.cidn } });

    return response.data.data;
  } catch (error) {
    throw error;
  }
}

// This is v2 of the above, trying to clean up
export async function fetchTenant() {
  const token = decodeToken(); // This should be handled better.

  if (!token) {
    throw Error("No identity token found.");
  }

  const response = await axiosInstance.get<TdnResponse<TenantData[]>>("/api/tdn/account/v1/tenants", {
    params: { cidn: token.cidn },
  });

  return response.data.data;
}

export async function fetchMicaData(tenancyId: string, options?: AxiosRequestConfig) {
  const response = await axiosInstance.get<TdnResponse<ProvisioningConfig>>(
    `/api/tdn/account/v1/tenants/${tenancyId}/provisioning/config`,
    options
  );

  return response.data.data;
}

type Sector = {
  sectorName: string;
  AAB_Mbps: number;
  latitude: number;
  longitude: number;
  bearing: number;
  beamwidth: number;
};

type GeographicBoundary = {
  properties: {
    FID: number;
    description: string;
  };
  geometry: {
    type: string;
    coordinates: [];
  };
  type: string;
};
export type ServiceAreaData = {
  serviceAreaName: string;
  serviceAreaId: string;
  supportTier: string;
  stateTerritory: string;
  state: string;
  description: string;
  lastStateChange: string;
  sectors: Sector[];
  geographicBoundary: GeographicBoundary;
  id: string;
};

export async function fetchServiceAreaData(serviceAreaName: string) {
  const response = await axiosInstance.get<TdnResponse<ServiceAreaData>>(
    `/api/tdn/account/v1/service-areas/${serviceAreaName}`
  );

  return response.data.data;
}

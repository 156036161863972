import { useParams } from "react-router-dom";
import useNotificationRecipient from "services/hooks/useNotificationRecipient";
import { deleteNotificationRecipient, RecipientForm, updateNotificationRecipient } from "../components/RecipientForm";

function stringInsert(string: string, insertions: Array<{ index: number; value: string }>) {
  insertions.sort((a, b) => b.index - a.index);
  if (!string) return null;
  const stringArray = string.split("");
  insertions.forEach(({ index, value }) => {
    stringArray.splice(index, 0, value);
  });

  return stringArray.join("");
}

// Do all the data manipulation in this layer and pass to form.
export function EditRecipientForm() {
  const params = useParams(); // :recipientId
  const { tenantId, data: recipientData, isLoading } = useNotificationRecipient({ recipientId: params.id });
  const recipientId = recipientData?.recipientId;

  if (isLoading) {
    return <p>Loading notification recipient data...</p>;
  }

  if (!tenantId) {
    return <p>No tenant ID found!!!</p>;
  }

  if (!recipientData) {
    return <p>No recipient data found!!!</p>;
  }

  // Add spaces back into the mobile number so the form doesn't complain.
  const mobileNormed = stringInsert(recipientData?.mobileNumber, [
    { index: 4, value: " " },
    { index: 7, value: " " },
  ]);

  // Set the default values for the form
  const defaultValues = {
    name: recipientData?.name || "",
    mobileNumber: mobileNormed || "",
    email: recipientData?.email || "",
    confirmEmail: recipientData?.email || "",
    isEmailEnabled: recipientData?.isEmailEnabled || false,
    isSmsEnabled: recipientData?.isSmsEnabled || false,
  };

  return (
    <RecipientForm
      template="Edit"
      defaultValues={defaultValues}
      formAction={(formData) => updateNotificationRecipient(formData, defaultValues, tenantId, recipientId)}
      deleteAction={() => deleteNotificationRecipient(tenantId, recipientId)}
    />
  );
}

import { SectionHeader } from "@able/react";
import { Subscription, TenantData } from "types";
import "./styles.scss";

type ServiceInformationProps = {
  tenancyInfo: TenantData;
  subscription: Subscription;
};

export const ServiceInformation = ({ tenancyInfo, subscription }: ServiceInformationProps) => {
  const { isAssigned, device, serviceNumber, simId, subscriptionId } = subscription;
  const saName = tenancyInfo.serviceArea?.[0]?.serviceAreaName || "Unknown";

  return (
    <div>
      <SectionHeader title="Service Information" variantLevel="Level1" headingElement="h2" />
      <dl className="info-list" aria-label="Service Information list">
        <div>
          <dt>Tenancy</dt>
          <dd>{tenancyInfo.tenancyName}</dd>
        </div>
        <div>
          <dt>Service area</dt>
          <dd>{saName}</dd>
        </div>
        <div>
          <dt>Subscription ID</dt>
          <dd>{subscriptionId}</dd>
        </div>
        <div>
          <dt>SIM serial</dt>
          <dd>{simId}</dd>
        </div>
        <div>
          <dt>Service number</dt>
          <dd>{serviceNumber || "No service number set"}</dd>
        </div>
        <div>
          <dt>IMEI</dt>
          <dd>{device?.equipmentId ?? "No device"}</dd>
        </div>
      </dl>
      {isAssigned && (
        <div>
          <SectionHeader title="Device Information" variantLevel="Level1" headingElement="h2" />
          <dl className="info-list" aria-label="Device Information list">
            <div>
              <dt>Make</dt>
              <dd>{device?.make}</dd>
            </div>
            <div>
              <dt>Model</dt>
              <dd>{device?.model}</dd>
            </div>
          </dl>
        </div>
      )}
    </div>
  );
};

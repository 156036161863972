import { Icon, ModalContent } from "@able/react";
import { useMutation } from "@tanstack/react-query";
import { PageHeading } from "components/ui/PageHeading";
import { queryClient } from "index";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { createUser } from "services/api/identity-service";
import { useBreadCrumbs } from "services/hooks/useBreadcrumbs";
import { ableUrl } from "utils/constants";
import { UserForm } from "../components/forms";
import { UserFormData } from "../components/forms/UserFormSchema";
import "./styles.scss";

export default function CreateUserPage() {
  useBreadCrumbs([
    { to: "/", label: "Dashboard" },
    { to: "/user-management", label: "User management" },
  ]);
  const navigate = useNavigate();
  const mutation = useMutation({ mutationFn: (formData: UserFormData) => createUser(formData) });

  function handleModalClose() {
    mutation.reset();
    queryClient.invalidateQueries({ queryKey: ["users"] });
    navigate("/user-management");
  }

  const bodyContent = useMemo(() => {
    if (mutation.status === "pending") {
      return (
        <div className="default-modal">
          <p>
            Submitting create request...
            <Icon icon="Clock" size="32" developmentUrl={ableUrl} />
          </p>
        </div>
      );
    }
    if (mutation.status === "success") {
      return (
        <div className="default-modal">
          <p>
            Success! User created
            <Icon icon="Success" size="32" developmentUrl={ableUrl} />
          </p>
        </div>
      );
    }

    if (mutation.status === "error") {
      return (
        <div className="error-modal">
          <p>
            Error! Something went wrong
            <Icon icon="Error" size="32" developmentUrl={ableUrl} />
          </p>
          <p>Details: {mutation.error.message}</p>
        </div>
      );
    }

    return <></>;
  }, [mutation.status, mutation.error?.message]);

  return (
    <main className="create-user-page">
      <div className="flex-column-container">
        <ModalContent
          isShowing={mutation.status !== "idle"}
          title="Create user submission"
          variant="Compact"
          setHideDialog={handleModalClose}
          footerContent={<></>}
          bodyContent={bodyContent}
          developmentUrl={ableUrl}
        />
        <PageHeading content="Create new user" />
        <UserForm onSubmit={(data) => mutation.mutate(data)} />
      </div>
    </main>
  );
}

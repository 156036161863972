import { SubServiceTier } from "types";

function stateFormatter(state: string, targetState: string) {
  if (!targetState) {
    const stateOutput = {
      inactive: "Inactive",
      pendingActivation: "Inactive",
      active: "Active",
      blocked: "Blocked",
      deactivated: "Deactivated",
    }[state];

    return stateOutput || "Error";
  }

  if (state === "pendingActivation" && targetState === "active") {
    return "Pending activation";
  }

  if (state === "active" && targetState === "blocked") {
    return "Pending block";
  }

  if (state === "blocked" && targetState === "active") {
    return "Pending unblock";
  }

  if ((state === "active" || state === "blocked") && targetState === "deactivated") {
    return "Pending deactivation";
  }

  return "Error";
}

function serviceTierFormatter(serviceTier: SubServiceTier) {
  const serviceTierValue = serviceTier.value;

  const output = {
    default: {
      value: "Default",
      unit: "0.2 Mbps Downlink / - Mbps Uplink",
    },
    low: {
      value: "Low",
      unit: "0.5 Mbps Downlink / 0.5 Mbps Uplink",
    },
    medium: {
      value: "Medium",
      unit: "1 Mbps Downlink / 1 Mbps Uplink",
    },
    high: {
      value: "High",
      unit: "5 Mbps Downlink / 5 Mbps Uplink",
    },
    processing: {
      value: "Processing",
      unit: "",
    },
  }[serviceTierValue];

  return output || { value: "Error", unit: "Error" };
}

export const serviceTierDefaults = {
  default: {
    value: "Default",
    unit: "0.2 Mbps Downlink / - Mbps Uplink",
  },
  low: {
    value: "Low",
    unit: "0.5 Mbps Downlink / 0.5 Mbps Uplink",
  },
  medium: {
    value: "Medium",
    unit: "1 Mbps Downlink / 1 Mbps Uplink",
  },
  high: {
    value: "High",
    unit: "5 Mbps Downlink / 5 Mbps Uplink",
  },
  processing: {
    value: "Processing",
    unit: "",
  },
};

export const formatters = {
  state: stateFormatter,
  serviceTier: serviceTierFormatter,
};

import { ActionButton } from "@able/react";
import { useQuery } from "@tanstack/react-query";
import { SearchCombobox } from "components/SearchCombobox";
import { PageHeading } from "components/ui/PageHeading";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { fetchSubscriptions } from "services/api/inventory-service";
import { useBreadCrumbs } from "services/hooks/useBreadcrumbs";
import useTenancy from "services/hooks/useTenancy";
import { Subscription } from "types";
import { TableContainer } from "./components/InventoryTable/TableContainer";
import "./services-container.scss";

export type TFilterValues = {
  matches: { id: string; value: string }[];
  fieldValue: string;
};

function filterInventory(subscriptions: Subscription[], fieldValue: string) {
  let matches: { id: string; value: string }[] = [];
  let fieldValueLower = fieldValue.toLowerCase();

  let filteredInventory = subscriptions.filter((sub) => {
    const { simId, subscriptionId, serviceNumber, description, name } = sub;

    if (simId && simId.includes(fieldValueLower)) {
      matches.push({ id: sub.id, value: simId });
      return true;
    }

    if (subscriptionId && subscriptionId.includes(fieldValueLower)) {
      matches.push({ id: sub.id, value: subscriptionId });
      return true;
    }

    if (serviceNumber && serviceNumber.includes(fieldValueLower)) {
      matches.push({ id: sub.id, value: serviceNumber });
      return true;
    }

    if (description && description.toLowerCase().includes(fieldValueLower)) {
      matches.push({ id: sub.id, value: description });
      return true;
    }

    if (name && name.toLowerCase().includes(fieldValueLower)) {
      matches.push({ id: sub.id, value: name });
      return true;
    }

    return false;
  });

  return { filteredInventory, matches };
}

const ServicesContainer = () => {
  // Fetch the subscriptions, and pass them the sort and filter values
  const [filterValues, setFilterValues] = useState<TFilterValues>({ matches: [], fieldValue: "" });
  const [filteredInventory, setFilteredInventory] = useState<Subscription[]>([]);

  useBreadCrumbs([{ label: "Dashboard", to: "/" }]);

  // Query
  const { data: tenantData } = useTenancy();

  const { data: rawData, isLoading } = useQuery({
    queryKey: ["subscriptions", tenantData?.tenancyId],
    queryFn: () =>
      tenantData &&
      fetchSubscriptions(tenantData.tenancyId).then((rawData) => {
        const { filteredInventory, matches } = filterInventory(rawData, filterValues.fieldValue);
        setFilterValues({ matches, fieldValue: "" });
        setFilteredInventory(filteredInventory);
        return rawData;
      }),
    enabled: !!tenantData,
    refetchOnWindowFocus: false,
  });

  function filter(fieldValue: string) {
    const { filteredInventory, matches } = filterInventory(rawData, fieldValue);
    setFilterValues({ matches, fieldValue });
    setFilteredInventory(filteredInventory);
  }

  return (
    <main>
      <Helmet>
        <title>Services - Telstra Dedicated Network</title>
      </Helmet>
      <PageHeading content="Services" />
      <div className="search-container">
        <SearchCombobox
          title="Filter services"
          matches={filterValues.matches}
          fieldValue={filterValues.fieldValue}
          onFieldChange={filter}
          helperText="Services will be filtered as you type."
        />
        <ActionButton
          developmentUrl="/able-sprites.svg"
          element="Link"
          label="Create a Hybrid service"
          to="/services/create"
          variant="MediumEmphasis"
        />
      </div>
      <TableContainer data={filteredInventory} isLoading={isLoading} />
    </main>
  );
};

export default ServicesContainer;

import { AxiosError, AxiosRequestConfig } from "axios";
import { type UserFormData } from "pages/user-management/components/forms/UserFormSchema";
import { TdnResponse, User } from "types";
import axiosInstance from "utils/axiosInstance";
import { stripNullish } from "utils/formatters/strip-nullish";

type TLoginParams = {
  code: string;
  nonce: string;
};

class ResponseError implements Error {
  name: string = "response-error";
  message: string;
  url: string = "unknown url";
  status: number = 500; // Internal error

  constructor(axiosError: AxiosError) {
    this.message =
      axiosError.response?.status === 404
        ? `Could not find requested resource from ${axiosError.config?.url}`
        : "Unknown error occurred, check http status.";

    if (axiosError.response?.status) this.status = axiosError.response.status;

    if (axiosError.config?.url) this.url = axiosError.config.url;
  }
}

export async function tdnLogin(params: TLoginParams, options?: AxiosRequestConfig) {
  const response = await axiosInstance.post("/api/tdn/identity/v1/login/external", undefined, { params, ...options });
  const data = response.data?.data;
  return data;
}

export async function tdnLogout(refreshTokenId: string, options?: AxiosRequestConfig) {
  const response = await axiosInstance.post("/api/tdn/identity/v1/logout", { refreshTokenId }, options);
  const data = response.data?.data;
  return data;
}

export async function fetchNewToken() {
  const token = sessionStorage.getItem("id_token");
  const refreshToken = sessionStorage.getItem("refresh_token");

  const response = await axiosInstance.post("/api/tdn/identity/v1/token/refresh", { token, refreshToken });

  const data = response.data?.data;
  return data;
}

export async function fetchUsersData() {
  try {
    const response = await axiosInstance.get<TdnResponse<User[]>>("/api/tdn/identity/v1/users");

    return response.data.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      throw new ResponseError(error);
    } else throw error;
  }
}

export async function fetchUserData(id: string) {
  try {
    const response = await axiosInstance.get<TdnResponse<User>>(`/api/tdn/identity/v1/users/${id}`);

    return response.data.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      throw new ResponseError(error);
    } else throw error;
  }
}

export async function createUser(formData: UserFormData) {
  try {
    const result = stripNullish(formData);

    delete result["confirmEmail"];

    const response = await axiosInstance.post<TdnResponse<void>>(`/api/tdn/identity/v1/users/`, { ...result });

    return response.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      throw new ResponseError(error);
    } else throw error;
  }
}

export async function updateUser({ id, data }: { id: string; data: { role: string } }) {
  try {
    const response = await axiosInstance.patch<TdnResponse<void>>(`/api/tdn/identity/v1/users/${id}`, data);

    return response.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      throw new ResponseError(error);
    } else throw error;
  }
}

export async function updateUserStatus({ id, isUserActive }: { id: string; isUserActive: boolean }) {
  try {
    const response = await axiosInstance.post<TdnResponse<void>>(`/api/tdn/identity/v1/users/${id}/status`, {
      isUserActive,
    });

    return response.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      throw new ResponseError(error);
    } else throw error;
  }
}

export async function deleteUser(id: string) {
  try {
    const response = await axiosInstance.delete<TdnResponse<void>>(`/api/tdn/identity/v1/users/${id}`);

    return response.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      throw new ResponseError(error);
    } else throw error;
  }
}

import { ActionButton, ModalDialogConfirmationDestructive } from "@able/react";
import { useRef } from "react";
import { ableUrl } from "utils/constants";

type Props = {
  onConfirmation: () => void;
};

export default function DeleteUserModal({ onConfirmation }: Props) {
  const deleteModalRef = useRef<HTMLDialogElement>(null);
  const buttonRef = useRef(null);

  return (
    <>
      <ModalDialogConfirmationDestructive
        developmentUrl={ableUrl}
        title="Confirm user deletion"
        cancelButtonLabel="Cancel"
        confirmButtonLabel="Confirm"
        stackButtonOnVXS
        focusOnCloseRef={buttonRef}
        description="You are about to permanently delete this user. Are you sure you wish to continue?"
        confirmButtonEvents={{ onClick: onConfirmation }}
        cancelButtonEvents={{ onClick: () => deleteModalRef.current?.close() }}
      >
        <ActionButton
          elementRef={buttonRef}
          element="button"
          label="Delete"
          variant="Destructive"
          deleteIcon={true}
          developmentUrl={ableUrl}
        />
      </ModalDialogConfirmationDestructive>
    </>
  );
}

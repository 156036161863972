import { ActionButton, ModalContent, SectionHeader, TextField } from "@able/react";
import { useMutation } from "@tanstack/react-query";
import { LabelledSection } from "components/LabelledSection";
import { queryClient } from "index";
import { MutableRefObject, SyntheticEvent, useRef, useState } from "react";
import { patchSubscriptionById } from "services/api/inventory-service";
import { Subscription } from "types";
import { ableUrl } from "utils/constants";
import { ServiceStatus } from "../ServiceStatus";
import { ServiceTier } from "../ServiceTier";

type ModalState = {
  open: boolean;
  modalType: "name" | "description";
  onCloseRef: MutableRefObject<HTMLElement | null> | null;
};

export const ServiceConfiguration = ({ subscription }: { subscription: Subscription }) => {
  const [modalState, setModalState] = useState<ModalState>({ open: false, modalType: "name", onCloseRef: null });
  const [input, setInput] = useState<string>("");
  const nameButtonRef = useRef<HTMLButtonElement>(null);
  const descButtonRef = useRef<HTMLButtonElement>(null);

  const { mutate } = useMutation({
    mutationFn: () => patchSubscriptionById(subscription, [[modalState.modalType, { value: input }]]),
    onSuccess: () => {
      handleModalClose();
      queryClient.invalidateQueries({ queryKey: ["subscriptions", subscription.id] });
    },
  });

  function handleModalClose() {
    setModalState((s) => ({ ...s, open: false }));
    if (modalState.onCloseRef?.current) modalState.onCloseRef.current.focus();
  }

  // Modal derived state
  const currentValue = subscription[modalState.modalType];
  const operation = currentValue ? "Change" : "Add";
  const label = modalState.modalType === "name" ? "reference name" : "description";

  return (
    <div>
      <SectionHeader title="Configuration settings" variantLevel="Level1" headingElement="h2" />
      <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        <ServiceStatus subscription={subscription} />
        <ServiceTier subscription={subscription} />
        <div>
          <LabelledSection title="Reference name" description={subscription?.name || "No reference name added."} />
          <ActionButton
            element="button"
            label={subscription?.name ? "Update reference name" : "Add reference name"}
            variant="LowEmphasis"
            events={{
              onClick: () => {
                setModalState({ open: true, modalType: "name", onCloseRef: nameButtonRef });
                setInput(subscription["name"]);
              },
            }}
            developmentUrl={ableUrl}
            // @ts-ignore
            elementRef={nameButtonRef}
          />
        </div>
        <div>
          <LabelledSection title="Description" description={subscription?.description || "No description added."} />
          <ActionButton
            element="button"
            label={subscription?.description ? "Update description" : "Add description"}
            variant="LowEmphasis"
            events={{
              onClick: () => {
                setModalState({ open: true, modalType: "description", onCloseRef: descButtonRef });
                setInput(subscription["description"]);
              },
            }}
            developmentUrl={ableUrl}
            // @ts-ignore
            elementRef={descButtonRef}
          />
        </div>
        <ModalContent
          title=""
          variant="Compact"
          bodyContent={
            <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
              <TextField
                id="name"
                label={`${operation} ${label}`}
                name={modalState.modalType}
                value={input}
                maxLength={20}
                events={{ onChange: (e: SyntheticEvent<HTMLInputElement>) => setInput(e.currentTarget.value) }}
              />
              <ActionButton
                element="button"
                label={operation === "Add" ? "Add" : "Update"}
                variant="HighEmphasisCompact"
                developmentUrl={ableUrl}
                events={{ onClick: mutate }}
              />
            </div>
          }
          developmentUrl={ableUrl}
          isShowing={modalState.open}
          focusOnCloseRef={modalState.onCloseRef as MutableRefObject<null>}
          setHideDialog={handleModalClose}
        />
      </div>
    </div>
  );
};

import { MessageInline } from "@able/react";
import { MutationStatus } from "@tanstack/react-query";
import { ableUrl } from "utils/constants";
import "./styles.scss";

type Props = {
  status: MutationStatus;
  successMessage?: string;
  errorMessage?: string;
  processingMessage?: string;
};

export function MutationResultMessage({
  status,
  processingMessage = "Processing request...",
  errorMessage = "An error occurred while submitting the request. Please try again later.",
  successMessage = "Success!",
}: Props) {
  switch (status) {
    case "pending": {
      return (
        <MessageInline className="mutation-message" variant="Information" developmentUrl={ableUrl}>
          {processingMessage}
        </MessageInline>
      );
    }
    case "error": {
      return (
        <MessageInline className="mutation-message" variant="Error" developmentUrl={ableUrl}>
          {errorMessage}
        </MessageInline>
      );
    }
    case "success": {
      return (
        <MessageInline className="mutation-message" variant="Success" developmentUrl={ableUrl}>
          {successMessage}
        </MessageInline>
      );
    }
    default:
      return null;
  }
}

import { ActionButton, MessageInline } from "@able/react";
import { MutationStatus, useMutation, useQuery } from "@tanstack/react-query";
import { PageHeading } from "components/ui/PageHeading";
import { queryClient } from "index";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { deleteUser, fetchUserData, updateUser, updateUserStatus } from "services/api/identity-service";
import { useBreadCrumbs } from "services/hooks/useBreadcrumbs";
import { User } from "types";
import { ableUrl } from "utils/constants";
import { MutationResultMessage } from "../components";
import EditUserForm from "../components/forms/EditUserForm";
import DeleteUserModal from "../components/modals/DeleteUserModal";
import UpdateStatusModal from "../components/modals/UpdateStatusModal";
import "./styles.scss";

export default function EditUserPage() {
  const params = useParams();
  const navigate = useNavigate();
  useBreadCrumbs([
    { label: "Dashboard", to: "/" },
    { label: "User management", to: "/user-management" },
  ]);

  const [mutationStatus, setMutationStatus] = useState<{ status: MutationStatus; successMessage: string }>({
    status: "idle",
    successMessage: "",
  });

  const { data, isLoading, isError, error } = useQuery<User>({
    queryKey: ["users", params.id],
    queryFn: () => fetchUserData(params.id as string),
    enabled: !!params.id,
  });

  const updateMutation = useMutation({
    mutationFn: ({ id, data }: { id: string; data: { role: string } }) => updateUser({ id, data }),
    onMutate: () => setMutationStatus({ status: "pending", successMessage: "" }),
    onSuccess: (_res, vars) => {
      setMutationStatus({ status: "success", successMessage: "Success! User successfully updated." });
      queryClient.invalidateQueries({ queryKey: ["users", vars.id] });
    },
  });

  const statusMutation = useMutation({
    mutationFn: ({ id, isUserActive }: { id: string; isUserActive: boolean }) => updateUserStatus({ id, isUserActive }),
    onMutate: () => setMutationStatus({ status: "pending", successMessage: "" }),
    onSuccess: (_res, vars) => {
      setMutationStatus({ status: "success", successMessage: "Success! User successfully updated." });
      queryClient.invalidateQueries({ queryKey: ["users", vars.id] });
    },
  });

  const deleteMutation = useMutation({
    mutationFn: (id: string) => deleteUser(id),
    onMutate: () => setMutationStatus({ status: "pending", successMessage: "" }),
    onSuccess: () => {
      setMutationStatus({ status: "success", successMessage: "Success! User successfully deleted. Redirecting..." });
      queryClient.invalidateQueries({ queryKey: ["users"] });
      setTimeout(() => navigate("/user-management"), 1000);
    },
  });

  if (isLoading) {
    return <div>Loading user data</div>;
  }

  if (isError) {
    return (
      <div>
        <MessageInline variant="Error" description={`An Error has occurred fetching the user data; ${error.message}`} />
      </div>
    );
  }

  if (!data) {
    return (
      <div>
        <MessageInline variant="Error" description={`No data found for user ${params.id}`} developmentUrl={ableUrl} />
      </div>
    );
  }

  return (
    <main className="edit-user-page">
      <div className="flex-column gap-1rem">
        <PageHeading content="Edit user" />
        <MutationResultMessage status={mutationStatus.status} successMessage={mutationStatus.successMessage} />
        <div className="flex items-center space-between">
          <h2>User Details</h2>
          <Link to={"/user-management"}>Cancel</Link>
        </div>
        <EditUserForm userData={data} onSubmit={(id, data) => updateMutation.mutate({ id, data })}>
          <div className="edit-form-button-group">
            <DeleteUserModal onConfirmation={() => deleteMutation.mutate(data.id)} />
            <UpdateStatusModal
              onConfirmation={() => statusMutation.mutate({ id: data.id, isUserActive: !data.active })}
              active={data.active}
            />
            <ActionButton element="button" label="Update" variant="HighEmphasis" type="submit" />
          </div>
        </EditUserForm>
      </div>
    </main>
  );
}

import { ActionButton, ModalDialogConfirmation, ModalDialogCriticalDecision } from "@able/react";
import { useRef, useState } from "react";
import { ableUrl } from "utils/constants";

type ModalProps = {
  callback: () => void;
};

export function BlockModalWithButton({ callback }: ModalProps) {
  const buttonRef = useRef(null);
  const [showing, setShowing] = useState<boolean>(false);

  function handleConfirm() {
    setShowing(false);
    callback();
  }

  return (
    <>
      <ModalDialogConfirmation
        title="Block Service?"
        description="Are you sure you want to block this service? Changes may take up to 24 hours and cannot be reversed until completed."
        cancelButtonLabel="Cancel"
        confirmButtonLabel="Confirm"
        cancelButtonEvents={{}}
        confirmButtonEvents={{ onClick: handleConfirm }}
        isShowing={showing}
        setHideDialog={() => setShowing(false)}
        stackButtonOnVXS={false}
        focusOnCloseRef={buttonRef}
        developmentUrl={ableUrl}
      />
      <ActionButton
        elementRef={buttonRef}
        element="button"
        label="Block"
        variant="LowEmphasis"
        // @ts-ignore
        icon="Paused"
        events={{ onClick: () => setShowing(true) }}
        developmentUrl={ableUrl}
      />
    </>
  );
}

export function DeactivateModalWithButton({ callback }: ModalProps) {
  const buttonRef = useRef(null);
  const [showing, setShowing] = useState<boolean>(false);

  function handleConfirm() {
    setShowing(false);
    callback();
  }

  return (
    <>
      <ModalDialogCriticalDecision
        title="Deactivate Service?"
        description="Are you sure you want to deactivate this service? Changes may take up to 24 hours and cannot be reversed until completed. Deactivation cannot be undone!"
        preferredButtonLabel="Confirm"
        preferredButtonEvents={{ onClick: handleConfirm }}
        secondaryButtonLabel="Cancel"
        secondaryButtonEvents={{}}
        isShowing={showing}
        setHideDialog={() => setShowing(false)}
        stackButtonOnVXS={true}
        focusOnCloseRef={buttonRef}
        developmentUrl={ableUrl}
      />
      <ActionButton
        elementRef={buttonRef}
        element="button"
        label="Deactivate"
        variant="LowEmphasis"
        // @ts-ignore
        icon="Delete"
        events={{ onClick: () => setShowing(true) }}
        developmentUrl={ableUrl}
      />
    </>
  );
}

export function UnblockModalWithButton({ callback }: ModalProps) {
  const buttonRef = useRef(null);
  const [showing, setShowing] = useState<boolean>(false);

  function handleConfirm() {
    setShowing(false);
    callback();
  }

  return (
    <>
      <ModalDialogConfirmation
        title="Unblock Service?"
        description="Are you sure you want to unblock this service? Changes may take up to 24 hours and cannot be reversed until completed."
        cancelButtonLabel="Cancel"
        confirmButtonLabel="Confirm"
        cancelButtonEvents={{}}
        confirmButtonEvents={{ onClick: handleConfirm }}
        isShowing={showing}
        setHideDialog={() => setShowing(false)}
        stackButtonOnVXS={false}
        focusOnCloseRef={buttonRef}
        developmentUrl={ableUrl}
      />
      <ActionButton
        elementRef={buttonRef}
        element="button"
        label="Unblock"
        variant="LowEmphasis"
        // @ts-ignore
        icon="VideoPlay"
        events={{ onClick: () => setShowing(true) }}
        developmentUrl={ableUrl}
      />
    </>
  );
}

import NotificationManagement from "./NotificationManagement";

export default NotificationManagement;
export { ConfirmationScreen } from "./components/ConfirmationScreen/ConfirmationScreen";
export {
  createNotificationRecipient,
  deleteNotificationRecipient,
  RecipientForm,
  updateNotificationRecipient,
} from "./components/RecipientForm";

import { Grid, SkipLink } from "@able/react";
import { Navigation } from "components/Navigation";
import { Breadcrumbs } from "components/ui/Breadcrumbs";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useOnRouteChange } from "services/hooks";
import { useAppStore } from "services/state/store/app-store";
import Loader from "../components/Loader";

export const ProtectedRoutes = () => {
  const initializeApp = useAppStore((state) => state.initializeApp);
  const loginError = useAppStore((state) => state.loginError);
  const loggedIn = useAppStore((state) => state.loggedIn);
  const setAutoFocus = useAppStore((state) => state.setAutoFocus);
  useOnRouteChange(() => setAutoFocus(true));

  useEffect(() => {
    const controller = new AbortController();
    initializeApp({ signal: controller.signal });
    return () => controller.abort();
  }, [initializeApp]);

  if (!loggedIn) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Loader size={56} />
        <p>Checking credentials...</p>
        {loginError && <p>{loginError.message}</p>}
      </div>
    );
  }

  return (
    <div className="app-layout-container">
      <SkipLink contentId="#content" />
      <Navigation />
      <Breadcrumbs />
      <Grid>
        <Outlet />
      </Grid>
    </div>
  );
};

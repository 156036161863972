import { useQuery } from "@tanstack/react-query";
import { fetchNotificationRecipients } from "services/api/account-service";
import useTenancy from "./useTenancy";

/**
 * Returns a useQuery hook for returning the notification recipients, with initial data is an empty array.
 */
export default function useNotificationRecipients() {
  // There is only ever a single tenant related to the CIDN in the token.
  const { data: tenantData, isLoading, isError } = useTenancy();

  // !! is to transform the object or value into a boolean, not a truthy/falsy
  // When we have tenant data AND NOT loading AND NOT error.
  const hookEnabled = !!tenantData && !isLoading && !isError;

  const recipientsQuery = useQuery({
    queryKey: ["notification-recipients"],
    queryFn: () => tenantData && fetchNotificationRecipients(tenantData.tenancyId),
    enabled: hookEnabled,
    refetchOnWindowFocus: false,
    staleTime: 3 * 60 * 1000, // 3 minutes
  });

  const isLoadingEither = isLoading || recipientsQuery.isLoading;

  return { tenant: tenantData, ...recipientsQuery, isLoading: isLoadingEither };
}

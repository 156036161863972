import { ActionButton, Sheet } from "@able/react";
import { PropsWithChildren, useState } from "react";

type Props = {
  buttonLabel: string;
  buttonVariant:
    | "HighEmphasis"
    | "HighEmphasisCompact"
    | "MediumEmphasis"
    | "MediumEmphasisCompact"
    | "LowEmphasis"
    | "LowEmphasisBig"
    | "Destructive"
    | "HighEmphasisDestructive";
  sheetHeading: string;
  buttonShown?: boolean;
} & PropsWithChildren;

export function SheetWithButton({ buttonLabel, buttonVariant, sheetHeading, buttonShown = true, children }: Props) {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      {buttonShown && (
        <ActionButton
          element="button"
          label={buttonLabel}
          variant={buttonVariant}
          events={{ onClick: () => setOpen(true) }}
        />
      )}
      <Sheet heading={sheetHeading} isOpened={open} onClose={() => setOpen(false)}>
        {children}
      </Sheet>
    </>
  );
}

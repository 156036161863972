import { Spacing } from "@able/react";
import { Tabs } from "components/Tabs";
import { PageHeading } from "components/ui/PageHeading";
import { Outlet, useMatch, useParams } from "react-router-dom";
import { useBreadCrumbs } from "services/hooks/useBreadcrumbs";
import { useSubscription } from "services/hooks/useSubscription";

export const ServiceContainer = () => {
  const { id } = useParams();
  const matchPerformance = useMatch("/services/:id/performance");
  const { data: subscription, isLoading } = useSubscription(id);

  useBreadCrumbs([
    { label: "Dashboard", to: "/" },
    { label: "Services", to: "/services" },
  ]);

  if (isLoading) {
    return (
      <div>
        <h1 className="loading">Checking for subscription</h1>
      </div>
    );
  }

  if (!subscription) {
    return (
      <div>
        <h1 id="content" tabIndex={-1}>
          Subscription not found!
        </h1>
      </div>
    );
  }

  return (
    <main>
      <PageHeading content={subscription?.name || `SIM ID: ${subscription.simId}`} />
      <Tabs
        defaultSelected={matchPerformance ? 0 : 1}
        tabs={[
          {
            label: "Performance",
            to: "performance",
            content: (
              <Spacing top="spacing2x">
                <Outlet context={{ subscription }} />
              </Spacing>
            ),
          },
          {
            label: "Configuration",
            to: "configuration",
            content: (
              <Spacing top="spacing2x">
                <Outlet context={{ subscription }} />
              </Spacing>
            ),
          },
        ]}
      />
    </main>
  );
};

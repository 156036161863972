import { Column, Grid, Spacing, TextStyle } from "@able/react";
import { PageHeading } from "components/ui/PageHeading";
import { Helmet } from "react-helmet-async";
import { useBreadCrumbs } from "services/hooks/useBreadcrumbs";
import useProvisioningConfig from "services/hooks/useProvisioningConfig";
import { getTokenData } from "utils/tokens";
import "./Dashboard.scss";
import CustomIllustration from "./Illustration";
import { ServiceTierCounts } from "./ServiceTierCounts";

function getGreeting(name: string) {
  let curHr = new Date().getHours();
  if (curHr < 12) return "Morning, " + name;
  if (curHr < 18) return "Afternoon, " + name;
  if (curHr < 23) return "Evening, " + name;
  else return "Hello, " + name;
}

function CustomerReferenceId({ micaAccountNumber }: { micaAccountNumber: string | undefined }) {
  if (!micaAccountNumber) {
    return null; // Could return a shimmer here.
  }

  return (
    <TextStyle alias="Base" element="p">
      {`Customer Reference: TDN-${micaAccountNumber}`}
    </TextStyle>
  );
}

function TenancyInformation() {
  const { tenant, data: provisioningConfig, isLoading } = useProvisioningConfig();

  return (
    <div>
      {isLoading && (
        <div>
          <p className="animated-background"></p>
          <p className="animated-background"></p>
          <p className="animated-background"></p>
        </div>
      )}
      {!isLoading && (
        <div>
          {tenant ? (
            <>
              <p className="para-light">{tenant.customerName}</p>
              <p className="para-light">{tenant.tenancyName}</p>
              <p className="para-light">{tenant.serviceArea[0].serviceAreaName}</p>
            </>
          ) : null}
          <CustomerReferenceId micaAccountNumber={provisioningConfig?.micaAccountNumber} />
          <p className="para-useful-link">Useful links</p>
        </div>
      )}
    </div>
  );
}

export const DashboardContainer = () => {
  useBreadCrumbs([]);

  let decodedToken = getTokenData();

  return (
    <main>
      <Helmet>
        <title>Telstra Dedicated Network</title>
      </Helmet>
      <Spacing top="spacing7x">
        <Grid>
          <Column cols={6}>
            <PageHeading content={getGreeting(decodedToken?.firstName || "user not retrieved!")} />
            <TenancyInformation />
          </Column>
          <Column cols={6}>
            <CustomIllustration />
          </Column>
          <Column className="grid-item--vxs-padding-bottom" cols={3} vxs={12} vsm={6} vmd={4}>
            <ServiceTierCounts />
          </Column>
        </Grid>
      </Spacing>
    </main>
  );
};

import { Column, Grid } from "@able/react";
import { Helmet } from "react-helmet-async";
import { useOutletContext } from "react-router-dom";
import useTenancy from "services/hooks/useTenancy";
import { Subscription } from "types";
import { ServiceInformation } from "../ServiceInformation";
import { ServiceConfiguration } from "./ServiceConfiguration";

export const ConfigurationContainer = () => {
  const { subscription } = useOutletContext<{ subscription: Subscription }>();
  const { data, isLoading } = useTenancy();

  if (isLoading) {
    return <div className="loading">Loading Data</div>;
  }

  if (!data) return <div>No Tenant data to display.</div>;

  return (
    <Grid>
      <Helmet>
        <title>Service Configuration - Telstra Dedicated Networks</title>
      </Helmet>
      <Column cols={6} vxs={12}>
        <ServiceConfiguration subscription={subscription} />
      </Column>
      <Column cols={6} vxs={12}>
        <ServiceInformation tenancyInfo={data} subscription={subscription} />
      </Column>
    </Grid>
  );
};

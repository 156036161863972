import { BillEmails104, Picto, Spacing, SupportPhone104 } from "@able/react";
import { PageHeading } from "components/ui/PageHeading";
import { Helmet } from "react-helmet-async";
import { useBreadCrumbs } from "services/hooks/useBreadcrumbs";
import "./HelpContainer.scss";

export const HelpContainer = () => {
  useBreadCrumbs([{ to: "/", label: "Dashboard" }]);

  return (
    <main>
      <Helmet>
        <title>Help and Support - Telstra Dedicated Network</title>
      </Helmet>
      <PageHeading content="Help and support" className="alias-HeadingA" style={{ margin: "1rem 0" }} />
      <p className="alias-BaseBig">
        We're here to help you with any questions you have about your dedicated network services.
      </p>

      <Spacing top="spacing2x" bottom="spacing2x">
        <h2 className="alias-HeadingB">Contact us</h2>
      </Spacing>
      <div className="help-contents">
        <Picto>
          <SupportPhone104 />
        </Picto>
        <div>
          <p className="alias-Base">Call us on: 1800 955 836</p>
          <ul>
            <li className="alias-Base">Option 1: Enquiries</li>
            <li className="alias-Base">Option 2: Raise a fault</li>
          </ul>
        </div>
        <Picto>
          <BillEmails104 />
        </Picto>
        <div>
          <p className="alias-Base">Email us here</p>
          <a className="alias-Base" href="mailto:TDNbilling@team.telstra.com">
            TDNbilling@team.telstra.com
          </a>
        </div>
      </div>
    </main>
  );
};

import { ActionButton, Lozenge, MessageInline, Sheet, Spacing } from "@able/react";
import { useMutation } from "@tanstack/react-query";
import { ServiceTierRadioGroup } from "components/ServiceTierRadioGroup/ServiceTierRadioGroup";
import { queryClient } from "index";
import { useState } from "react";
import { updateServiceTierById } from "services/api/inventory-service";
import { Subscription } from "types";
import { formatters } from "utils";
import { ableUrl } from "utils/constants";
import "./styles.scss";

type Props = {
  subscription: Subscription;
};

export function ServiceTier({ subscription }: Props) {
  const { serviceTier } = subscription;
  const formattedTier = formatters.serviceTier(serviceTier);
  const label = formattedTier.unit ? `${formattedTier.value}: ${formattedTier.unit}` : formattedTier.value;
  const [targetServiceTier, setTargetServiceTier] = useState(subscription.serviceTier.value);
  const [sheetOpen, setSheetOpen] = useState<boolean>(false);

  // Put the error checking in too from the variables here.
  const { mutate } = useMutation({
    mutationFn: () => updateServiceTierById(subscription, targetServiceTier.toUpperCase()),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["subscriptions", subscription.id] }),
    onSettled: () => setSheetOpen(false),
  });

  function handleSheetClose() {
    setSheetOpen(false);
    queryClient.invalidateQueries({ queryKey: ["subscriptions", subscription.id] });
  }

  // If the subscription is not yet active, don't allow the option to change service tier.
  if (subscription.state === "pendingActivation") return null;

  return (
    <div>
      <p className="service-status-label">Change service tier</p>
      <p className="service-status-fineprint">
        The service tier defines the priority and minimum bandwidth of individual services that belong to it.
      </p>
      <Spacing top="spacing1nHalf" bottom="spacing2x" className={["lozenge-group"]}>
        <Lozenge label={label} variant="Neutral" />
      </Spacing>
      <ActionButton
        element="button"
        label="Change speed tier"
        variant="LowEmphasis"
        events={{ onClick: () => setSheetOpen(true) }}
      />
      <Sheet heading="Change speed tier" isOpened={sheetOpen} onClose={handleSheetClose}>
        <div className="service-tier-sheet-contents">
          {serviceTier.isTransitioning ? (
            <MessageInline
              variant="Attention"
              developmentUrl={ableUrl}
              description={"A service tier change is currently being processed."}
            />
          ) : (
            <>
              <ServiceTierRadioGroup
                state={targetServiceTier}
                onChange={(e) => setTargetServiceTier(e.currentTarget.value)}
              />
              <ActionButton
                element="button"
                label="Update"
                variant="HighEmphasisCompact"
                events={{ onClick: mutate }}
              />
            </>
          )}
        </div>
      </Sheet>
    </div>
  );
}

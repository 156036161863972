import { RadioButton, RadioGroup } from "@able/react";
import { ChangeEvent } from "react";

export const ServiceTierRadioGroup = ({
  state,
  onChange,
}: {
  state: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <div>
      <p className="alias-LabelA1">Service speed tier</p>
      <p className="alias-FinePrintA" id="speed-tier-description">
        Change the tier speed may reduce minimum bandwidth of all devices on the same tier if the tier is approaching
        maximum capacity.
      </p>
      <RadioGroup variant="Comfortable" name="Service tier change" describedById="speed-tier-description">
        <RadioButton
          id="radio-button-high"
          value="high"
          label="High"
          description="5 Mbps Downlink / 5 Mbps Uplink"
          checked={state === "high"}
          events={{ onChange }}
        />
        <RadioButton
          id="radio-button-medium"
          value="medium"
          label="Medium"
          description="1 Mbps Downlink / 1 Mbps Uplink"
          checked={state === "medium"}
          events={{ onChange }}
        />
        <RadioButton
          id="radio-button-low"
          value="low"
          label="Low"
          description="0.5 Mbps Downlink / 0.5 Mbps Uplink"
          checked={state === "low"}
          events={{ onChange }}
        />
        <RadioButton
          id="radio-button-default"
          value="default"
          label="Default"
          description="0.2 Mbps Downlink / - Mbps Uplink"
          checked={state === "default"}
          events={{ onChange }}
        />
      </RadioGroup>
    </div>
  );
};
